import React from "react";

import { Layout, SEO } from '../../components/structure';
import { Button, Content, Hero, PremUpgradeContainer, Testimonial, PointsLoyalty, CallToAction, SeatBlocker, SpeedPass, LoyaltyCommerce } from "../../components/blocks"
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../styles";
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"



const IndustryCruisePage = (props) => {

  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "Plusgrade-Hero-Banner-Air.jpg" }) {
       ...FullHeroBackground
      },
      rightArrowIcon: file(base: {eq: "arrow-right-blue.png"}) {
        ...ButtonIcon
      }
      rightArrowIconWhite: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
      airIcon: file(base: {eq: "AirplaneIcon.png"}) {
        childImageSharp {
          fixed(width: 40, height: 40, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clickUpgradeIcon: file(base: { eq: "click-upgrade.png" }) {
        childImageSharp {
            fixed(width: 45, height: 80, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      partnerIncrease: file(base: { eq: "partnerIncrease.png" }) {
          childImageSharp {
              fixed(width: 150, height: 150, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      revenueIncrease: file(base: { eq: "revenueIncrease.png" }) {
          childImageSharp {
              fixed(width: 150, height: 150, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      cloudUpload: file(base: { eq: "cloudUpload.png" }) {
          childImageSharp {
              fixed(width: 70, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      creditCard: file(base: { eq: "creditCard.png" }) {
          childImageSharp {
              fixed(width: 60, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      offerDistribute: file(base: { eq: "offerDistribute.png" }) {
          childImageSharp {
              fixed(width: 70, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      revPlatformBackground: file(base: { eq: "IndustryCruiseRevenuePlatform.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airTestimonials: file(base: { eq: "IndustryAirTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      growthArrow: file(base: { eq: "ArrowClimber.png" }) {
        childImageSharp {
          fixed(width: 500, height: 300, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airCanada: file(base: { eq: "AC_Inline.png" }) {
        ...CruiseLogo
      }
      lingus: file(base: { eq: "AerLingus_Inline.png" }) {
        ...CruiseLogo
      }
      latam: file(base: { eq: "Latam_Inline.png" }) {
        ...CruiseLogo
      }
      va: file(base: { eq: "VA_Inline.png" }) {
        ...CruiseLogo
      }
      lufthansa: file(base: { eq: "Lufthansa_Inline.png" }) {
        ...CruiseLogo
      }
      singapore: file(base: { eq: "Singapore_Inline.png" }) {
        ...CruiseLogo
      }
      japan: file(base: { eq: "JapanAirlines.png" }) {
        ...CruiseLogo
      }
      etihad: file(base: { eq: "EtihadGold.png" }) {
        ...CruiseLogo
      }
      qantas: file(base: { eq: "Qantas.png" }) {
        ...CruiseLogo
      }
      whiteLabel: file(base: { eq: "whiteLabelIcon.png" }) {
        childImageSharp {
            fixed(width: 110, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      zeroDilution: file(base: { eq: "zeroDilutionIcon.png" }) {
        childImageSharp {
            fixed(width: 140, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      provenGrowth: file(base: { eq: "provenGrowth.png" }) {
        childImageSharp {
            fixed(width: 120, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      merchantRecord: file(base: { eq: "merchantRecordIcon.png" }) {
        childImageSharp {
            fixed(width: 90, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      fullInt: file(base: { eq: "fullIntegrationIcon.png" }) {
        childImageSharp {
            fixed(width: 70, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      fastestTime: file(base: { eq: "fastestTimeIcon.png" }) {
        childImageSharp {
            fixed(width: 95, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      simpleUX: file(base: { eq: "simpleUX.png" }) {
        childImageSharp {
            fixed(width: 50, height: 60, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      generateAncillary: file(base: { eq: "generateAncillary.png" }) {
        childImageSharp {
            fixed(width: 58, height: 60, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      inventoryControl: file(base: { eq: "inventoryControl.png" }) {
        childImageSharp {
            fixed(width: 50, height: 60, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }

    }
  `);

  const PlatformRevenueContainer = styled.div`
  
  ${ font.imports.sherika }
    width: 100%;
    height: auto;
    min-height: 500px;
    margin: auto;
    position: relative;

    .background-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      filter: grayscale(1) contrast(1.2);

      &:after {
        content: '';
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:linear-gradient(0deg, rgba(0, 4, 20, 0.65), rgba(0, 4, 20, 0.65));
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .content-con {
      position: relative;
      z-index: 400;
      width: 95%;
      max-width: 1400px;
      margin: auto;
      height: 100%;
      padding: 20px 0;

      h3 {
        font-size: 38px;
        text-align: center;
        color: ${brand.colors.pg_blue};
        font-weight: 600;
        text-shadow: 0 0 40px ${brand.colors.pg_primary_dark};
      }

      h4 {
        font-size: 24px;
        text-align: center;
        color: #fff;
        font-weight: 600;
      }

      .icon-content-con {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        top: 66px;
        margin-bottom: 0;

        @media only screen and ${mq.maxMd} {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          top: 0;
          width: 95%;
          margin: auto;
          max-width: 500px;
        }
      }
    }

  `;

  const OutperformContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    background-color: ${brand.colors.pg_primary_light};

    .left-section {
      flex-basis: 50%;
      height: 450px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .right-section {
      height: 450px;
      flex-basis: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 90px;

      .blue-highlight {
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        display: block;
      }

      .copy {
        display: block;
        font-size: 22px;
        color: #000414;
        width: 54%;
        max-width: 350px;
        margin: 15px 0;
      }
    }
  `;

  const PlatformPerksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1050px;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    gap: 40px;
    padding: 60px 0;

    @media only screen and ${mq.maxMd} {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      max-width: 400px;
    }



    .headline-highlight{
      display: none;
    }
  `

const CruiseParnters = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px auto;

    .cruise-logo-container {
      width: 95%;
      max-width: 1400px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      @media only screen and ${mq.maxMd} {
        flex-wrap: wrap;
        justify-content: center;
      }

      .logo-con {
        flex-basis: 200px;
      }
    }
    
`

const PlatformDetailContainer = styled.div`
    width: 100%;
    background-color: ${brand.colors.pg_blue};

    .inner-icon-container {
      width: 95%;
      max-width: 1020px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      gap: 40px;
      
      @media only screen and ${mq.maxMd} {
        width: 80%;
      }
    }
`

const SpeedPassPerksContainer = styled.div`
  width: 100%;
  background-color: #F6F6F6;

    .inner-speedpass-icon-con {
      width: 95%;
      max-width: 1020px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      gap: 40px;

      @media only screen and ${mq.maxMd} {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        max-width: 400px;
      }
    }
`
  return (
    <Layout>
      <SEO title="Air | Plusgrade" 
        description={'Plusgrade accelerates your incredible passenger experiences while generating significant ancillary revenue on every flight.'}
        keywords={'airline, flight, upgrades'}
      />
      <Content whiteBG>
        <Hero 
          title={'Air'}
          image = {pageQuery['hero']}
          icon = {pageQuery['airIcon']}
          subtitle = {"Deliver incredible passenger experiences while generating significant ancillary revenue on every flight."}
          buttonURL = {'/contact/'}
          buttonLabel = {'Get started'}
          buttonIcon = {pageQuery['rightArrowIconWhite']}
          fullBG
        />


      <CruiseParnters>
        <div className="cruise-logo-container">
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['airCanada'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['japan'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['latam'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['lufthansa'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['etihad'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['qantas'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
        </div>
        <Button destination={'/partners/'} label={'See all partners'} icon={pageQuery['rightArrowIconWhite']} black whiteText pill/>
      </CruiseParnters>

        <div style={{backgroundColor: "#F6F6F6", paddingTop: "1px"}}>
          <PremUpgradeContainer 
            copy = {"Enable passengers to bid or purchase a premium seat through a white-labeled user experience. As the most widely adopted upgrade solution in the industry, <strong>Premium Upgrade</strong> drives high-margin revenue for over 80 airlines around the world."}
          />
        </div>
        <PlatformRevenueContainer>
          <div className="background-con">
            <GatsbyImage fixed={pageQuery['revPlatformBackground'].childImageSharp.fixed} imgStyle={{objectFit: "cover", objectPosition: "50% 80%"}} />
          </div>
          <div className="content-con">
            <div className="icon-content-con">
              <IconContainer 
                icon = {pageQuery['revenueIncrease']}
                headline = {'225%+'}
                copy = {'upgrade revenue in the first year of Plusgrade implementation —&nbsp;European flag carrier'}
              />

              <IconContainer 
                icon = {pageQuery['partnerIncrease']}
                headline = {'5x'}
                copy = {'engagement after implementing Plusgrade\'s Bid-to-Upgrade'}
              />

              <IconContainer 
                icon = {pageQuery['revenueIncrease']}
                headline = {'2x'}
                copy = {'upgrade revenue in 12 month period after switching to Plusgrade —&nbsp;Major North American carrier'}
              />
            </div>
          </div>
          
        </PlatformRevenueContainer>

        <PlatformDetailContainer>
           <div className="inner-icon-container">
            <IconContainer whiteOnBlue
                  icon = {pageQuery['whiteLabel']}
                  headline = {'White-label solutions'}
                  copy = {'Airline-branded solution that provides a seamless customer experience.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['zeroDilution']}
                  headline = {'Zero dilution of core revenues'}
                  copy = {'Robust business rules and levers designed to optimize your upgrade program.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['fastestTime']}
                  headline = {'Fastest time-to-market'}
                  copy = {'Start generating incremental revenue in 12 weeks.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['fullInt']}
                  headline = {'Full PSS integration'}
                  copy = {'Fast and easy implementation and no fees for setup or maintenance.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['merchantRecord']}
                  headline = {'Merchant of Record'}
                  copy = {'Upgrade revenue gets paid immediately and goes directly to you.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['provenGrowth']}
                  headline = {'Proven YoY revenue growth'}
                  copy = {'Maximize profit with diversified products and revenue streams.'}
              />
           </div>
        </PlatformDetailContainer>

        <SeatBlocker />

        <PlatformPerksContainer>
          <IconContainer whiteOrange
                icon = {pageQuery['cloudUpload']}
                headline = {'Plug-and-play setup'}
                copy = {'Go live in as few as four weeks. Plus, all reporting and program management live in one place.'}
            />

            <IconContainer whiteOrange
                icon = {pageQuery['creditCard']}
                headline = {'Easy payment integration'}
                copy = {'Use the same payment gateway, currencies and payment cards in your existing PSS.'}
            />

            <IconContainer whiteOrange
                icon = {pageQuery['offerDistribute']}
                headline = {'Maximized offer distribution'}
                copy = {'Leverage high-performing media channels to reach the broadest audience.'}
            />
          
        </PlatformPerksContainer>

        <Testimonial 
          testimonials={
            [
              'This is a product that gives Azores Airlines a whole new category of seats to offer our passengers, giving them the opportunity to have just that extra space and comfort that makes a difference in their travel experience.--Marco Silva, Ancillaries, Direçào de Rede e Receita, Network and Revenue, Sata Azores Airlines',
              'Plusgrade is always looking for the best for their partners and  open to new initiatives and continuous improvement. They are the key components in the development of Ancillary Revenue for Copa Airlines.--Commercial VP, Ancillary Revenue Manager, Copa Airlines',
              "…if you’re sitting in economy, sitting next to empty seats is about as good as it gets.--ThePointsGuy.com, Etihad review"
            ]
          }
          background={pageQuery['airTestimonials']}
          imageStyle = {{objectPosition: "center center"}}
        />


        <SpeedPass fr={false}/>
        
        <SpeedPassPerksContainer>

          <div className="inner-speedpass-icon-con">
            <IconContainer whiteOrange
                  icon = {pageQuery['simpleUX']}
                  headline = {'Simple, seamless UX'}
                  copy = {'Mobile-first design with gamified time-savings estimates.'}
              />

              <IconContainer whiteOrange
                  icon = {pageQuery['generateAncillary']}
                  headline = {'Generate ancillary revenue'}
                  copy = {'Increase customer satisfaction and monetize in-airport touchpoints before your passengers fly.'}
              />

              <IconContainer whiteOrange
                  icon = {pageQuery['inventoryControl']}
                  headline = {'Control inventory & leverage granular pricing rules'}
                  copy = {'Target passengers with configurations based on eligibility and inventory.'}
              />
            </div>
        </SpeedPassPerksContainer>

        <LoyaltyCommerce />

        <PointsLoyalty
          headline={'Loyalty Commerce Platform'}
          spaceBetween
        />


        <CallToAction
          title={'Drive ancillary revenue\nwith incredible customer experiences'}
          subtitle={'Contact us today to learn more.'}
          > 
          <Button destination={'/contact/request-a-demo'} label={'Request a demo'} icon={pageQuery['rightArrowIcon']} white blueText pill />
        </CallToAction>

      </Content>
    </Layout>
  );
}

export default IndustryCruisePage;

const IconCon = styled.div`

${ font.imports.sherika }
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and ${mq.maxMd} {
    flex-basis: 95%;
  }

  .icon-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }

  }

  .headline-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline {
    font-family: ${font.family.sherika};
    font-weight: 600 !important;
    color: ${brand.colors.pg_orange} !important;
    font-size: 48px !important;
    margin-bottom: 1px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
  }

  p{
    margin-top: 1px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  ${props => props.whiteOrange && css`
    flex-basis: 30%;
    align-items: flex-start;
    flex-wrap: wrap;

    .headline-con {
      text-align: left;
      h4 {
        color: ${brand.colors.pg_primary_dark} !important;
        font-family: ${font.family.sherika};
        font-size: 22px !important;
        font-weight: 700;
        white-space: pre-line;
        @media only screen and ${mq.maxMd} {
          text-align: center;
        }
      }
      p {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-weight: 400;
        margin: 20px 0;
        min-height: 96px;
        @media only screen and ${mq.maxMd} {
          text-align: center;
        }
      }

      .headline{
        min-height: 60px;
        display: flex;
        align-items: center;
        @media only screen and ${mq.maxMd} {
          text-align: center;
          justify-content: center;
        }
      }
    }
  `}

  ${props => props.whiteOnBlue && css`
    flex-basis: 45%;
    align-items: flex-start;
    justify-content: center;
    text-align: left;

    .icon-con {
      text-align: left;
    }

    .headline-con {
      .headline {
        color: #fff !important;
        font-size: 26px !important;
      }
    }

    p {
      font-size: 16px !important;
      font-weight: 400 !important;
      margin-bottom: 0;
    }
  `}
`;

const IconContainer = ({icon, highlight, headline, copy, ...props }) => {
  const ic = ( icon ? <GatsbyImage fixed={icon.childImageSharp.fixed}/> : <></>);
  return(
    <IconCon {...props}>
      <div className="icon-con">
        {ic}
      </div>
      <div className="headline-con">
      <h4 className="headline-highlight">{highlight}</h4>
        <h4 className="headline">
          {headline}
        </h4>
        <p dangerouslySetInnerHTML={{__html: copy}}>
        </p>
      </div>

    </IconCon>
  )
}

export const query = graphql`
  fragment CruiseLogo on File {
    childImageSharp {
      fluid(maxWidth: 125, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

